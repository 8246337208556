import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import ProGet from "../components/ProGet.react";
import UserStore from "../stores/UserStore";
import ProTeaser from "../components/ProTeaser.react";
import HelmetDetails from "../components/HelmetDetails";

const getStateFromStores = () => {
  return {
    showProGet: UserStore.showProGet(),
    showPro: UserStore.showPro(),
  };
};

const PrivacyPolicyPage = () => {
  const [data, setData] = useState(getStateFromStores());

  const _onChange = () => {
    setData(getStateFromStores());
  };

  useEffect(() => {
    UserStore.addChangeListener(_onChange);
    return () => {
      UserStore.removeChangeListener(_onChange);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HelmetDetails
        title="piZap Privacy Policy and Terms of Use"
        description="piZap Privacy Policy and Terms of Use"
        imageUrl="https://static.pizap.com/pizapfiles/icons/512x512.png"
        webUrl="https://www.pizap.com/privacypolicy"
        keywords="Privacy Policy, Cookie Policy, Terms of Use"
      />
      <Header />
      <div className="privacy-policy">
        <div className="content-wrapper">
          <h1 id="top">Privacy Policy &amp; Terms of Use</h1>
          <p>
            <strong>Effective Date: Feb 3, 2025</strong>
          </p>

          <nav className="policy-nav">
            <ul>
              <li>
                <a href="#privacy-policy">Privacy Policy</a>
              </li>
              <li>
                <a href="#terms-of-use">Terms of Use</a>
              </li>
            </ul>
          </nav>

          <section className="summary">
            <h2>Summary of Key Points</h2>
            <p>
              This document explains how Digital Palette LLC ("we", "us", or
              "our") collects, uses, and protects your information when you use
              our website and mobile applications ("Services"). Please review
              this policy carefully.
            </p>
            <ul>
              <li>
                We collect only the information necessary to provide and
                improve our Services.
              </li>
              <li>
                A cookie consent banner is active on our website to manage your
                tracking preferences.
              </li>
              <li>
                Third-party services are limited to Facebook and Google for
                login and social sharing functionalities.
              </li>
              <li>
                Your rights are covered under applicable laws including GDPR
                and, for residents in the USA, additional rights under laws such
                as the CCPA.
              </li>
              <li>
                For more detailed inquiries or requests, please contact us.
              </li>
            </ul>
          </section>

          <section id="privacy-policy">
            <h2>Privacy Policy</h2>
            <p>
              At Digital Palette LLC, we are committed to protecting your
              privacy. This Privacy Policy explains, in broad terms, how we
              collect, use, disclose, and safeguard your personal data when you
              use our Services. By accessing or using our Services, you agree to
              the practices described herein.
            </p>

            <h3>1. Introduction</h3>
            <p>
              This policy applies to piZap.com, the piZap mobile applications on
              iOS and Android, and our family of apps. Our registered address is:
              140 W Franklin St. Ste 203 PMB 42, Monterey, CA 93940, USA.
            </p>

            <h3>2. Information We Collect</h3>
            <p>
              We collect information necessary to operate and improve our
              Services. The details remain broad to ensure flexibility in how we
              process and manage your data.
            </p>

            <h3>3. How We Use Your Information</h3>
            <p>
              Your information is used for providing and enhancing our Services,
              managing your account, and for general communication purposes. We
              keep our descriptions broad to allow for flexibility and legal
              compliance.
            </p>

            <h3>4. Cookies and Tracking Technologies</h3>
            <p>
              We use cookies and similar technologies to improve your experience.
              A cookie consent banner is available on our website. For more
              details, please refer to our{" "}
              <a href="#cookie-policy">Cookie Policy</a>.
            </p>

            <h3>5. Data Rights and Legal Compliance</h3>
            <p>
              We ensure that your rights are protected under applicable laws,
              including the GDPR. In addition, for residents of the United
              States, particularly California, additional rights under laws such
              as the California Consumer Privacy Act (CCPA) apply. Requests for
              data access, correction, or deletion are handled in accordance with
              the law.
            </p>

            <h3>6. Data Security and Breach Notification</h3>
            <p>
              We implement appropriate technical and organizational measures
              to safeguard your data. In the event of a data breach, we will
              notify you in accordance with legal requirements.
            </p>

            <h3>7. Third-Party Services</h3>
            <p>
              Our Services incorporate third-party functionalities provided by
              Facebook and Google, primarily for login and social sharing.
            </p>

            <h3>8. Children's Privacy</h3>
            <p>
              Our Services are not intended for children under the age of 13.
              We do not knowingly collect data from users under 13, and any
              necessary parental consent processes remain deliberately broad.
            </p>

            <h3>9. Changes to This Privacy Policy</h3>
            <p>
              We may update this Privacy Policy from time to time. Any changes
              will be posted on this page along with an updated effective date.
            </p>

            <h3>10. Contact Information</h3>
            <p>
              If you have any questions regarding this Privacy Policy or your
              data rights, please contact us at{" "}
              <a href="mailto:support@pizap.com">support@pizap.com</a>.
            </p>

            <h3 id="cookie-policy">Cookie Policy</h3>
            <p>
              We use cookies and similar tracking technologies to enhance your
              experience. You may manage your cookie preferences via your browser
              settings.
            </p>
          </section>

          <section id="terms-of-use">
            <h2>Terms of Use</h2>
            <h3>1. Acceptance of Terms</h3>
            <p>
              By accessing or using our Services, you agree to be bound by these
              Terms of Use and our Privacy Policy. If you do not agree, please do
              not use our Services.
            </p>

            <h3>2. Eligibility</h3>
            <p>
              You must be at least 13 years old to use our Services. For users
              under 16 in the European Economic Area, parental consent is
              required.
            </p>

            <h3>3. User Conduct</h3>
            <p>
              You agree to use our Services in compliance with all applicable
              laws and to refrain from any activities that could harm the
              Services or other users.
            </p>

            <h3>4. Intellectual Property Rights</h3>
            <p>
              All content provided through our Services is the property of
              Digital Palette LLC or its licensors and is protected by relevant
              intellectual property laws. User-generated content remains your
              property, though you grant us a license to use it for the operation
              of our Services.
            </p>

            <h3>5. Account Registration</h3>
            <p>
              You may be required to create an account to access certain features.
              You agree to provide accurate and current information and to keep
              your account credentials confidential.
            </p>

            <h3>6. Termination</h3>
            <p>
              We reserve the right to suspend or terminate your account at any
              time for conduct that violates these Terms or that is harmful to our
              Services.
            </p>

            <h3>7. Cancellation and Refund Policy</h3>
            <p>
              You may cancel your piZap PRO subscription at any time through your
              account settings or by contacting us. Refunds, if applicable, will be
              processed in accordance with our standard policies.
            </p>

            <h3>8. Limitation of Liability</h3>
            <p>
              To the fullest extent permitted by law, Digital Palette LLC shall
              not be liable for any indirect or consequential damages arising from
              your use of our Services.
            </p>

            <h3>9. Indemnification</h3>
            <p>
              You agree to indemnify and hold harmless Digital Palette LLC from any
              claims arising from your use of the Services or your violation of
              these Terms.
            </p>

            <h3>10. Modifications to the Terms</h3>
            <p>
              We may modify these Terms from time to time. Continued use of our
              Services after any changes signifies your acceptance of the updated
              Terms.
            </p>

            <h3>11. Governing Law and Dispute Resolution</h3>
            <p>
              These Terms are governed by the laws of the State of California,
              USA. Any disputes will be resolved in accordance with applicable
              arbitration laws.
            </p>

            <h3>12. Severability and Entire Agreement</h3>
            <p>
              If any provision of these Terms is found to be unenforceable, the
              remaining provisions will remain in full force and effect. These
              Terms and our Privacy Policy together constitute the entire
              agreement between you and Digital Palette LLC.
            </p>

            <h3>13. Contact Information</h3>
            <p>
              For any questions regarding these Terms, please contact us at{" "}
              <a href="mailto:support@pizap.com">support@pizap.com</a>.
            </p>
          </section>
        </div>
      </div>
      <Footer />
      {data.showProGet && <ProGet />}
      {data.showPro && <ProTeaser />}
    </>
  );
};

export default PrivacyPolicyPage;